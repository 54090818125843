import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image";
import {AnchorLink} from "gatsby-plugin-anchor-links";

const Aprojects = () => (
    <Layout>
        <Seo title="Список объектов компании ГОРКА Инжиниринг" />
        <StaticImage
            src="../images/header.jpg"
            width={1600}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Горка Инжиниринг"
            layout={'constrained'}
            objectPosition={'center 60%'}
            style={{
                width: '100%'
            }}
        />
        <div className="container">
            <h1>Список объектов компании ГОРКА Инжиниринг</h1>

            <p>Путепровод через ж/д на 4 км автодороги «Москва – Бородино», Тучково, Рузский район (дорожно-мостовое направление)</p>

            <p>Капитальный ремонт деревообрабатывающего завода (ДОЗ) ГБУ Ритуал, расположенного по адресу: г. Москва, Савёловский проезд, д.10 (здания и сооружения)</p>

            <p>Двухуровневая транспортная развязка на пересечении Дмитровского и Долгопрудненского шоссе (2-4-7 пусковые комплексы), (инженерные сети)</p>

            <p>Локальные очистные сооружения хозяйственно-бытовых стоков ГКУЗ города Москвы «Детский кардиоревматологический санаторий №20 «Красная Пахра» (инженерные сети)</p>

            <p>Ледовый дворец спорта «Кристалл», г. Электросталь, МО (здания и сооружения)</p>

            <p>Выполнение строительных работ по объекту: Реконструкция мостового перехода через р. Осётр у н.п. Берхино на автомобильной дороге «Луховицы – Городна – Берхино», Луховицкий район (дорожно-мостовое направление)</p>

            <p>Перекладка дождевой канализации по объекту строительство дороги «Солнцево – Бутово – Видное» (ТПУ Саларьево), (инженерные сети)</p>

            <p>Прокладка водопровода по объекту строительство дороги «Солнцево – Бутово – Видное» (ТПУ Саларьево), (инженерные сети)</p>

            <p>Городские инженерные коммуникации для комплексной реконструкции квартала 353а района Замоскворечье (инженерные сети)</p>

            <p>Строительство путепровода через ж/д на 19км автомобильной дороги «ММК Павловская слобода – Нахабино» у платформы Нахабино (дорожно-мостовое направление)</p>

            <p>Строительство путепровода через ж/д пути на 16км Горьковского направления Московской железной дороги в районе станции Реутово (дорожно-мостовое направление)</p>

            <p>Строительство путепровода через ж/д на 5км автодороги «Балашиха – Салтыковка», Разинское шоссе (дорожно-мостовое направление)</p>

            <p>Строительство транспортной развязки на пересечении Волоколамского и Ильинского шоссе, Красногорский район, МО (1-ый пусковой комплекс), (строительный контроль)</p>

            <p>Морозовская городская детская клиническая больница. Лечебный корпус на 500 коек (технический заказчик)</p>

            <p>Транспортная развязка автомобильной дороги с пересечением в разных уровнях железнодорожных путей на 87-м км перегона Михнево – Жилево Московской железной дороги (строительный контроль)</p>

            <p>Путепровод через ж/д на 20км Носовихинского шоссе (строительный контроль)</p>

            <p>Строительство ПС 220 кВ «Купчинская» с заходами 220 кВ</p>

            <p>Объект культурного наследия федерального значения «Ансамбль Донского монастыря, XVI-XIX»: Башни №1, 2, 3, 6, 7,  «Новый собор» - технический надзор (здания и сооружения)</p>

            <p>Строительство и ввод в эксплуатацию автомобильных дорог Пермь - Березники 020+639-022+390, Пермь - Березники 022+390-025+768 и Восточный обход г. Перми 000+000-009+753.</p>
            <p>Реконструкция автомобильной дороги "Лобня- аэропорт Шереметьево</p>
            <p>«Реконструкция Петербургского спортивно-концертного комплекса, расположенного по адресу: Российская Федерация, г. Санкт-Петербург, муниципальный округ Гагаринское, пр. Юрия Гагарина, д. 8, кадастровый номер 78:14:0007633:3082, в целях создания многофункционального спортивно-концертного комплекса»</p>
            <p>«Строительство автомобильной дороги на участке Рублевский проезд - "Новый выход на МКАД с федеральной автомобильной дороги М-1 "Беларусь" Москва - Минск"</p>
            <p>«Многофункциональный жилой комплекс с подземной автостоянкой. расположенный по адресу: г. Москва, Большой Саввинский переулок. вл.2-4-6. стр. 1.5.6, 1.5.17; вл.4»</p>
            <p>«Реконструкция автомобильной дороги г. Жуковский ул. Туполева»</p>
            <p>«Музейный и театрально-образовательный комплексы в г. Владивосток»</p>
            <p>«Музейный и театрально – образовательный комплекс в г. Калининград»</p>
            <p>«Музейный и театрально-образовательный комплексы в г. Севастополе»</p>
            <p>«Музейный и театрально-образовательный комплексы в г. Кемерово»</p>
            <p>«Стадион Краснознаменного Черноморского флота»</p>
            <p>Работы по очистке от зеленых насаждений территории строительства объекта «Строительство скоростной автомобильной дороги Казань – Екатеринбург на участке Дюртюли – Ачит». Этап 2, км 140 – км 232, Пермский край. Подготовка территории строительства»</p>
            <p>М-12 «Строящаяся скоростная автомобильная дорога Москва - Нижний Новгород – Казань», 1 этап км 0 – км 80, Московская, Владимирская области (от пересечения с автомобильной дорогой федерального значения А-108 «Московское большое кольцо» до пересечения с автомобильной дорогой федерального значения М-7 «Волга»)</p>
            <p>Реконструкция храма Воскресения Христова. храма Рождества Пресвятой Богородицы и колокольни в селе Дединово, Луховицкий район, МО</p>
            <p>«Фармацевтическое производство в арендуемых помещениях по адресу: г. Москва, Волгоградский проспект, д.42. корпус 24»</p>
            <p>«Выполнение проектных и изыскательских работ и работ по строительству автомобильной дороги подъезд к д. Богданиха в Ленинском городском округе и городском округе Домодедово Московской области»</p>
            <p>«Реконструкция Каширского шоссе на участке км 0-4,4 в Ленинском районе»</p>

        </div>
        <div className={'but-up'}>
            <AnchorLink to={'/all-projects#main'}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-square-up" role="img"
                     xmlns="http://www.w3.org/2000/svg" width="36" height='36' viewBox="0 0 448 512">
                    <path fill="currentColor"
                          d="M0 432V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48zm207.029-281.941L71.515 285.573c-9.373 9.373-9.373 24.569 0 33.941l16.971 16.971c9.373 9.373 24.569 9.373 33.941 0L224 234.912l101.573 101.574c9.373 9.373 24.569 9.373 33.941 0l16.971-16.971c9.373-9.373 9.373-24.569 0-33.941L240.971 150.059c-9.373-9.373-24.569-9.373-33.942 0z"
                          className=""></path>
                </svg>
            </AnchorLink>
        </div>
    </Layout>
)

export default Aprojects